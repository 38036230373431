import "./src/styles.css"
import "@fontsource/rock-salt"
import "@fontsource/nunito/900.css"
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcoM_omAAAAAJ29s0d4dcrZYKKkjLVsq2A_wSSH">
            {element}
        </GoogleReCaptchaProvider>
    )
}